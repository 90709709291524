.MuiSlider-thumb {
  z-index: 1;
}
.slider-hide-thumb .MuiSlider-thumb {
  display: none;
}
.slider-active-markLabel .MuiSlider-markLabel {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
}
.slider-hide-track .MuiSlider-track {
  display: none;
}

.estate-info .MuiButton-outlinedSecondary {
  color: #000
}

.MuiButtonGroup-outlined .btn-status-2 {
  color: rgba(15, 114, 230, 0.5) !important;
  border-color: rgba(15, 114, 230, 0.5) !important;
}
.MuiButtonGroup-outlined .btn-status-3 {
  color: rgba(46, 125, 50, 0.5) !important;
  border-color: rgba(46, 125, 50, 0.5) !important;
}
.MuiButtonGroup-outlined .btn-status-4 {
  color: rgba(211, 47, 47, 0.5) !important;
  border-color: rgba(211, 47, 47, 0.5) !important;
}

.bg-warning {
  background-color: rgb(255, 245, 157, 0.5) !important;
}
.bg-success {
  background-color: rgba(46, 125, 50, 0.5) !important;
}
.bg-danger {
  background-color: rgba(211, 47, 47, 0.5) !important;
}

.MuiDataGrid-cell--withRenderer {
  position: relative;
}
.MuiDataGrid-cell--withRenderer .badge {
  background-color: #777;
  color: #fff;
  padding: 0 5px;
}

.MuiInputBase-formControl {
  background-color: #fff;
}

.MuiDataGrid-cell-stick-left,
.MuiDataGrid-cell-stick-top {
  position: sticky !important;
  z-index: 3;
  background: #fff;
}
.MuiDataGrid-cell-stick-left {
  left: 0;
  box-shadow: 10px 0 5px -2px rgb(224, 224, 224);
  border-right: 1px solid rgb(224, 224, 224);
}
.MuiDataGrid-cell-stick-top {
  top: 0;
}
.MuiDataGrid-cell-stick-left.left-100 {
  left: 100px;
}

.pull-right {
  position: absolute;
  left: auto;
  right: 0;
}
.pull-left {
  position: absolute;
  left: 0;
  right: auto;
}
.pull-top {
  position: absolute;
  top: 0;
  bottom: auto;
}
.pull-bot {
  position: absolute;
  top: auto;
  bottom: 0;
}
.pull-lt {
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  bottom: auto;
}
.pull-lb {
  position: absolute;
  left: 0;
  right: auto;
  top: auto;
  bottom: 0;
}
.pull-rt {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  bottom: auto;
}
.pull-rb {
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.fix-br {
  white-space: pre-line;
}

#menu-message .MuiList-padding {
  padding: 0 !important;
}

.MuiChip-colorSuccess {
  background-color: rgb(46, 125, 50, 0.5) !important;
}
.MuiChip-colorWarning {
  background-color: rgb(237, 108, 2, 0.5) !important;;
}
.MuiChip-colorError {
  background-color: rgb(211, 47, 47, 0.5) !important;;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.spinner-text {
  margin-top: 10px;
}

.ErrorPage {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.ErrorPage img {
  max-width: 100%;
  height: auto;
}
